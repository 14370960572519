.App {
  text-align: start;
}

.login-form {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.reviews-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ant-image .ant-image-img {
  object-fit: contain;
}

.upload_img {
  margin-top: 10px;
  width: 430px;
  height: 300px;
  object-fit: cover;
}